import React from "react";
import Content from '../views/Services';

export default ({location}) => (
  <Content location={location} seo={{
    title: "Discover connected car services powered by OBI+",
    lang: 'en',
    description: "OBI+ provides digital services through connected car technology for professionals, fleet managers & car drivers.",
    meta: [{
      name: 'keywords',
      content: 'Digital services',
    }]
  }}/>
);